import { Link } from "gatsby"
import React from "react"
import "../styles/layout.css"

export default function BlogEntry(props) {
  return (
    <div className="mx-auto max-w-4xl px-10 py-6 bg-white dark:bg-gray-700 rounded-lg shadow-2xl text-gray-600 dark:text-gray-200">
      <div className="mt-2">
        {props.header}
        <p className="mt-2">{props.preview}</p>
      </div>

      <div className="flex justify-between items-center mt-4">
        {props.readMore}
      </div>
    </div>
  )
}
