import React from "react"
import Moment from "react-moment"
import { Link } from "gatsby"
import HeaderSub from "../components/header_sub"
import LayoutOuter from "../components/layout_outer"
import LayoutInner from "../components/layout_inner"
import BlogEntry from "../components/blog_entry"

const BlogLink = props => (
  <Link to={props.to}>
    <p className="text-2xl text-gray-700 dark:text-gray-200 font-bold hover:underline">
      {props.title}
    </p>
  </Link>
)

const BlogLinkReadMore = props => (
  <Link to={props.to}>
    <h1 className="max-w-max bg-gradient-to-r from-tempory_blue_400 to-tempory_logo_pink">
      Read more
    </h1>
  </Link>
)

export default function Blog() {
  return (
    <LayoutOuter>
      <LayoutInner>
        <div className="py-16">
          <HeaderSub
            title="Blog"
            subtitle="The latest information about app updates"
          />
          <div className="pt-16">
            <BlogEntry
              header={
                <div>
                  <Moment format="d MMM yyyy">
                    {new Date(Date.UTC(2023, 11, 30, 0, 0, 0))}
                  </Moment>

                  <BlogLink
                    to="/blog/2023/general-availability"
                    title="Announcing General Availability"
                  />
                </div>
              }
              readMore={<BlogLinkReadMore to="/blog/2023/general-availability" />}
              preview="We're finally stable! We decided to remove some features from the beta that were causing delays in development or were not requested by our customers. This means..."
              author="André Thiele"
            />
            <div className="pt-16"/>
            <BlogEntry
              header={
                <div>
                  <Moment format="d MMM yyyy">
                    {new Date(Date.UTC(2021, 2, 26, 0, 0, 0))}
                  </Moment>

                  <BlogLink
                    to="/blog/2021/launch-of-beta"
                    title="Launching the Tempory Beta"
                  />
                </div>
              }
              readMore={<BlogLinkReadMore to="/blog/2021/launch-of-beta" />}
              preview="We're now beginning the internal beta to stabilize the app before making it publicly available to ensure there are no problems on a variety of..."
              author="André Thiele"
            />
          </div>
        </div>
      </LayoutInner>
    </LayoutOuter>
  )
}
